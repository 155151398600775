/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import "./RegisterForm.styles.sass";
import { Link } from "react-router-dom";
import showeye from "../../../assets/images/eye.png";
import hideeye from "../../../assets/images/hide.png";
import PasswordStrengthChecker from "../../../components/common/PasswordStrengthChecker/PasswordStrengthChecker";
import Input from "../../../components/common/Input/Input";
import { useTranslation } from "react-i18next";
import { postSignUpThunkAction } from "../../../redux/auth/auth.slice";
import { useAppDispatch } from "../../../redux/hooks";

import { isPostSignUpSelector } from "../../../redux/auth/auth.selectors";
import { useSelector } from "react-redux";
import Spiner from "../../../components/common/Spiner/Spiner";
import { CEXModal } from "@careexpand/cex-core-components/esm";
const RegisterForm = () => {
    const [, setStatus] = useState<string>();
    const [, setIsSubmitting] = useState<boolean>();
    const signUpSelector = useSelector(isPostSignUpSelector);

    const [t]: any = useTranslation("global");
    const dispatch = useAppDispatch();
    const [registerError, setRegisterError] = useState<boolean>(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordInput, setPasswordInput] = useState();
    const [errorMsg, setErrorMsg] = useState<any>();
    const [acceptanceChecked, setAcceptanceChecked] = useState<boolean>(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
    const [passwordFieldError, setPasswordFieldError] = useState(false);
    const [repeatPasswordFieldError, setRepeatPasswordFieldError] = useState(false);

    const closeModal = () => {
        setRegisterError(false);
    };

    const handleAcceptanceClick = () => {
        setAcceptanceChecked(!acceptanceChecked);
    };
    const checkPasswordStrength = (password: any) => {
        const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
        const mediumPassword = new RegExp(
            "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
        );
        if (password === "") setPasswordStrength(0);
        if (strongPassword.test(password)) {
            setPasswordStrength(3);
        } else if (mediumPassword.test(password)) {
            setPasswordStrength(2);
        } else {
            setPasswordStrength(1);
        }
    };

    const handleOnSubmit = async (values: any) => {
        setIsSubmitting(false);
        setStatus("status");
        await dispatch(
            postSignUpThunkAction({
                data: {
                    email: values.email,
                    firstName: values.name,
                    lastName: values.lastName,
                    phone: values.phone,
                    timezone: "UTC",
                    language: "en",
                    password: values.password,
                },
            })
        ).then((res: any) => {
            if (res.error) {
                setRegisterError(true);
                setErrorMsg(res.error.message.includes("401") ? "Email already exists" : "Error creating user");
            } else {
                window.location.assign("/#/login/?redirectFrom=register");
            }
        });
    };
    return (
        <div className="userDetailsForm">
            {registerError && (
                <CEXModal handleModal={() => closeModal()} title={"Register error"} subtitle={errorMsg} type={"info"} />
            )}
            {signUpSelector && <Spiner />}
            <Formik
                initialValues={{ email: "", name: "", lastName: "", password: "", repeatpassword: "", phone: "" }}
                // onSubmit={(values, actions) => {
                // setIsSubmitting(true);
                // const isFormFilled = Object.values(values).every(x => x === null || x === "" || x === undefined);
                // if (!acceptanceChecked) {
                //     setRegisterError(true);
                //     setErrorMsg(t("register.terms-policy-error"));
                // } else {
                //     if (isFormFilled) {
                //         setStatus("error");
                //         setRegisterError(true);
                //         setErrorMsg("Check that all the fields are filled correctly");
                //     } else {
                //         handleOnSubmit(values);
                //     }
                // }
                // }}
                onSubmit={() => {
                    return;
                }}
                validationSchema={yup.object().shape({
                    name: yup
                        .string()
                        .required("Please, fill with your name")
                        .min(2, "Name should be at least 2 characters"),
                    lastName: yup
                        .string()
                        .required("Please, fill with your last name")
                        .min(2, "Last Name should be at least 2 characters"),
                    email: yup
                        .string()
                        .email("Please enter a valid email address")
                        .required("Please enter a valid email address"),
                    password: yup
                        .string()
                        .required("Please, fill with your password")
                        .min(8, "Please, fill with your password"),
                    repeatpassword: yup
                        .string()
                        .required("Passwords must match")
                        .oneOf([yup.ref("password"), null], "Passwords must match"),
                    phone: yup
                        .string()
                        .matches(
                            /^[+#*\(\)\[\]]*([0-9][ ext+-pw#*\(\)\[\]]*){11,45}$/,
                            "Please, fill with a valid Phone number"
                        )
                        .required("Please, fill with a valid Phone number"),
                })}
            >
                {({ errors, touched, submitCount, values, validateForm, handleBlur }: any) => (
                    <Form className="userDetailsForm">
                        <div className="inputFieldDivRegister">
                            <div className="rowInputFields">
                                <h6>{t("register.name")}</h6>
                                <Field name="name" type="text" className="input" placeholder={t("register.name")} />
                                <label className="input__error">
                                    {errors.name && (touched.name || submitCount > 0) && errors.name}
                                </label>
                            </div>
                            <div className="rowInputFields">
                                <h6>{t("register.last-name")}</h6>
                                <Field
                                    name="lastName"
                                    type="text"
                                    className="input"
                                    placeholder={t("register.last-name")}
                                />
                                <label className="input__error">
                                    {errors.lastName && (touched.lastName || submitCount > 0) && errors.lastName}
                                </label>
                            </div>
                        </div>

                        <div className="inputFieldDivRegister">
                            <div className="rowInputFields">
                                <h6>{t("register.phone")}</h6>
                                <Input
                                    name="phone"
                                    placeholder={t("register.phone-label")}
                                    validateOnChange={false}
                                    type="phone"
                                />
                                <label className="input__error">
                                    {errors.phone && (touched.phone || submitCount > 0) && errors.phone}
                                </label>
                            </div>
                            <div className="rowInputFields">
                                <h6>{t("register.email")} </h6>
                                <Field
                                    name="email"
                                    type="email"
                                    className="input"
                                    placeholder={t("register.email-label")}
                                />
                                <label className="input__error">
                                    {errors.email && (touched.email || submitCount > 0) && errors.email}
                                </label>
                            </div>
                        </div>
                        <div className="inputFieldDivRegister">
                            <div className="rowInputFields">
                                <h6>{t("register.password")}</h6>
                                <div style={{ display: "flex" }}>
                                    <Field
                                        name="password"
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder={t("register.password-label")}
                                        className="input password__input"
                                        validate={(e: any) => {
                                            setPasswordInput(e);
                                            checkPasswordStrength(passwordInput);
                                            setPasswordFieldError(errors.password && errors.password ? true : false);
                                        }}
                                        onClick={() => {
                                            setPasswordFieldError(false);
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className="show-password__icon">
                                        <img
                                            className="clickable"
                                            src={isPasswordVisible ? showeye : hideeye}
                                            alt=""
                                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                        />
                                    </span>
                                    <span className="tooltiptext">
                                        You must include at least a capital and small letter, a number an a special
                                        character.
                                    </span>
                                </div>

                                <label className="input__error">
                                    {passwordFieldError &&
                                        errors.password &&
                                        (touched.password || submitCount > 0) &&
                                        errors.password}
                                </label>
                            </div>
                            <div className="rowInputFields">
                                <h6>{t("register.repeat-password")}</h6>
                                <div style={{ display: "flex" }}>
                                    <Field
                                        name="repeatpassword"
                                        type={isRepeatPasswordVisible ? "text" : "password"}
                                        placeholder={t("register.repeat-password-label")}
                                        className="input"
                                        onBlur={handleBlur}
                                        validate={() => {
                                            setRepeatPasswordFieldError(
                                                errors.repeatpassword && errors.repeatpassword ? true : false
                                            );
                                        }}
                                    />
                                    <span className="show-password__icon">
                                        <img
                                            className="clickable"
                                            src={isRepeatPasswordVisible ? showeye : hideeye}
                                            alt=""
                                            onClick={() => setIsRepeatPasswordVisible(!isRepeatPasswordVisible)}
                                        />
                                    </span>
                                </div>
                                <label className="input__error">
                                    {repeatPasswordFieldError &&
                                        errors.repeatpassword &&
                                        (touched.repeatpassword || submitCount > 0) &&
                                        errors.repeatpassword}
                                </label>
                            </div>
                        </div>

                        <PasswordStrengthChecker passwordStrength={passwordStrength} />
                        <div className="privacyPolicyContainer">
                            <div className="commonText">
                                <input
                                    onChange={() => {
                                        handleAcceptanceClick();
                                    }}
                                    required
                                    type="checkbox"
                                    checked={acceptanceChecked}
                                    className="registerFormCheckbox clickable"
                                />
                                <>
                                    {t("register.accept-terms")}{" "}
                                    <a
                                        href={`https://${
                                            process.env.NODE_ENV === ("development" || "production") ? "dev." : ""
                                        }careexpand.com/termsandconditions.html`}
                                        className="linkText"
                                    >
                                        {t("register.terms-policy")}
                                    </a>
                                </>
                            </div>
                        </div>
                        <div className="btn__container">
                            <div>
                                <button
                                    type="submit"
                                    className="signUpButton"
                                    style={{
                                        fontSize:
                                            window.innerWidth <= 320
                                                ? "11px"
                                                : window.innerWidth === 768
                                                ? "10px"
                                                : window.innerWidth >= 426 && window.innerWidth <= 514
                                                ? "10px"
                                                : "14px",
                                    }}
                                    onClick={() => {
                                        validateForm();
                                        if (errors && Object.keys(errors).length === 0) {
                                            handleOnSubmit(values);
                                        }
                                    }}
                                >
                                    {t("register.register-button")}
                                </button>
                            </div>
                            <div className="backSignIn">
                                <Link to="/login" className="backSignIn" style={{ textDecoration: "none" }}>
                                    {t("register.login-link")}
                                </Link>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default RegisterForm;
