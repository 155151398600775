
import React from 'react';
import './AuthBody.styles.sass'
import carexLogo from '../../../assets/images/Careexpand-Logo-no-padding.png'
import loginIMG from '../../../assets/images/Teleconsultation-drawings.png'


const AuthBody: React.FC<any> = () => {

    return (
        <>
            <div className="carexLogoContainer1">
                <img className="carexLogo" src={carexLogo} alt="Careexpand_logo" />
                <div className="carexLogoText">The Operating System for Virtual Medicine</div>
            </div>
            <div className="loginImageContainer">
                <img className="loginImage" src={loginIMG} alt="LoginImage" />
            </div>
        </>
    )
};
export default AuthBody;