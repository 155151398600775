import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../api/auth.api";
import { AuthState, IPostForgotPassword, IPostResetPassword, IPostSignIn, IPostSignUp } from "./auth.types";

import { AxiosResponse } from "axios";
import { CEThunkPayload } from "../../api/gateway/carexGateway";
import { ConsoleLogger } from "../../utils/logger";

const initialState: AuthState = {
    isPostSignUpLoading: false,
    isPostSignInLoading: false,
    isPostResetPasswordLoading: false,
    isPostForgotPasswordLoading: false,
    examplePayload: {},
    loginResponse: {},
};
const logger = new ConsoleLogger();

const payloadFactory = (payload: CEThunkPayload<any>, service: Promise<any>) => {
    const success = payload.onSuccess ? payload.onSuccess : (res: AxiosResponse) => Promise.resolve(res);
    const error = payload.onError ? payload.onError : (err: AxiosResponse) => Promise.reject(err);
    logger.log("payloadFact Success", success);
    logger.log("payloadFact err", error);

    return service.then(success).catch(error);
};

export const postSignUpThunkAction = createAsyncThunk(
    "auth-app/auth/signUp",
    (payload: CEThunkPayload<IPostSignUp>) => {
        return payloadFactory(payload, AuthService.postSignUp(payload.data));
    }
);

export const postSignInThunkAction = createAsyncThunk(
    "auth-app/auth/signIn",
    (payload: CEThunkPayload<IPostSignIn>) => {
        logger.log("payloadSlice", payload);

        return payloadFactory(payload, AuthService.postSignIn(payload.data));
    }
);

export const postForgotPasswordThunkAction = createAsyncThunk(
    "auth-app/auth/forgotPassword",
    (payload: CEThunkPayload<IPostForgotPassword>) => {
        return payloadFactory(payload, AuthService.postForgotPassword(payload.data));
    }
);

export const postResetPasswordThunkAction = createAsyncThunk(
    "auth-app/auth/resetPassword",
    (payload: CEThunkPayload<IPostResetPassword>) => {
        return payloadFactory(payload, AuthService.postResetPassword(payload.data));
    }
);

const authSlice = createSlice({
    name: "authSlice",
    initialState,
    reducers: {
        resetAuthState: () => initialState,
    },
    extraReducers: builder => {
        builder.addCase(postSignUpThunkAction.pending, (state: AuthState) => {
            state.isPostSignUpLoading = true;
        });
        builder.addCase(postSignUpThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostSignUpLoading = false;
            state.loginResponse = payload;
            logger.log("success response", payload);
        });
        builder.addCase(postSignUpThunkAction.rejected, (state: AuthState, {}) => {
            state.isPostSignUpLoading = false;
        });

        builder.addCase(postSignInThunkAction.pending, (state: AuthState) => {
            state.isPostSignInLoading = true;
        });
        builder.addCase(postSignInThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostSignInLoading = false;
            state.examplePayload = payload;
            const groupFlags = payload.flags && payload.flags.groupFlags ? payload.flags.groupFlags : null;
            const flags = payload.flags && payload.flags.userFlags ? payload.flags.userFlags : null;
            const disabledLeftNavItems = payload.disabledLeftNavItems;
            const userFlags = {
                ...groupFlags,
                ...flags,
            };
            logger.log("success response: ", payload);
            localStorage.setItem("Token", payload.jwtToken);
            localStorage.setItem("missingAgreements", payload.missingAgreements);
            localStorage.setItem("userFlags", userFlags ? JSON.stringify(userFlags) : "");
            localStorage.setItem(
                "disabledLeftNavItems",
                disabledLeftNavItems ? JSON.stringify(disabledLeftNavItems) : JSON.stringify({})
            );
        });
        builder.addCase(postSignInThunkAction.rejected, (state: AuthState, {}) => {
            state.isPostSignInLoading = false;
        });

        builder.addCase(postForgotPasswordThunkAction.pending, (state: AuthState) => {
            state.isPostForgotPasswordLoading = true;
        });
        builder.addCase(postForgotPasswordThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostForgotPasswordLoading = false;
            state.examplePayload = payload;
            //logger.log("success response", payload);
        });
        builder.addCase(postForgotPasswordThunkAction.rejected, (state: AuthState) => {
            state.isPostForgotPasswordLoading = false;
        });

        builder.addCase(postResetPasswordThunkAction.pending, (state: AuthState) => {
            state.isPostResetPasswordLoading = true;
        });
        builder.addCase(postResetPasswordThunkAction.fulfilled, (state: AuthState, { payload }) => {
            state.isPostResetPasswordLoading = false;
            state.examplePayload = payload;
            logger.log("success response", payload);
        });
        builder.addCase(postResetPasswordThunkAction.rejected, (state: AuthState) => {
            state.isPostResetPasswordLoading = false;
        });
    },
});

export const { resetAuthState } = authSlice.actions;
export default authSlice.reducer;
