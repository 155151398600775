import { Field } from "formik";
import "./Input.styles.sass";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Input = (props: any) => {
    const { placeholder, type, name, validateOnChange = false, ...rest } = props;
    return (
        <Field
            name={name}
            {...rest}
            render={({ field, form }: any): any => {
                const { touched, errors, submitCount } = form;
                const error =
                    (validateOnChange || (touched[name] || submitCount > 0)) && typeof errors[name] === "string" ? errors[name] : null;

                const onChange = validateOnChange
                    ? (e: any) => {
                        //   if (validateOnChange) {
                        //       validateField(field.name);
                        //   }
                          return field.onChange(e);
                      }
                    : field.onChange;

                const handlePhoneNumberUpdate = (value: any, country: any, e: any) => {
                    onChange(e);
                    e.preventDefault();
                };

                if (type === "phone") {
                    return (
                        <div>
                            <PhoneInput
                                specialLabel={""}
                                country={"us"}
                                autoFormat={true}
                                inputProps={{
                                    name: "phone",
                                    country: "us",
                                    required: true,
                                    autoFocus: false,
                                }}
                                value={field.value}
                                onChange={handlePhoneNumberUpdate}
                                containerStyle={{
                                    border: "none",
                                }}
                            />
                            {/* {field.value.length > 0 && field.name === name && error && (
                                <div className="input__error-label">{error}</div>
                            )} */}
                        </div>
                    );
                }

                if (type === "password") {
                    return (
                        <div>
                            <PhoneInput
                                specialLabel={""}
                                country={"us"}
                                autoFormat={true}
                                inputProps={{
                                    name: "phone",
                                    country: "us",
                                    required: true,
                                    autoFocus: false,
                                }}
                                value={field.value}
                                onChange={handlePhoneNumberUpdate}
                                containerStyle={{
                                    border: "none",
                                }}
                            />
                            {field.value.length > 0 && field.name === name && error && (
                                <div className="input__error-label">{error}</div>
                            )}
                        </div>
                    );
                }

                return (
                    <div>
                        <input {...field} onChange={onChange} type={type} className="input" placeholder={placeholder} />
                        {field.value.length > 0 && field.name === name && error && (
                            <div className="input__error-label">{error}</div>
                        )}
                    </div>
                );
            }}
        />
    );
};
export default Input;
