import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import "./ResetPasswordForm.styles.sass";
import { ConsoleLogger } from "../../../utils/logger";
import showeye from "../../../assets/images/eye.png";
import hideeye from "../../../assets/images/hide.png";
const logger = new ConsoleLogger();
import { postResetPasswordThunkAction } from "../../../redux/auth/auth.slice";
import PasswordStrengthChecker from "../../../components/common/PasswordStrengthChecker/PasswordStrengthChecker";
import { useAppDispatch } from "../../../redux/hooks";
import Spiner from "../../../components/common/Spiner/Spiner";
import { CEXModal } from "@careexpand/cex-core-components/esm";
const ResetPasswordForm = () => {
    const [status, setStatus] = useState<string>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>();
    const dispatch = useAppDispatch();
    const [t]: any = useTranslation("global");
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
    const [passwordInput, setPasswordInput] = useState();
    const [passwordStrength, setPasswordStrength] = useState(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchParams, _] = useSearchParams();
    const [modalValue, setModalValue] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const checkPasswordStrength = (password: any) => {
        const strongPassword = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
        const mediumPassword = new RegExp(
            "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))"
        );
        if (password === "") setPasswordStrength(0);
        if (strongPassword.test(password)) {
            setPasswordStrength(3);
        } else if (mediumPassword.test(password)) {
            setPasswordStrength(2);
        } else {
            setPasswordStrength(1);
        }
    };

    const closeModal = () => {
        if(status === "error"){
            setModalValue("")
        }else{

            window.location.assign("/");
        }
    };

    return (
        <div className="userDetailsForm">
            {modalValue && modalValue.length && <CEXModal
                    type="info"
                    handleModal={() => closeModal()}
                     title={"Attention"}
                    subtitle={modalValue} 
                />}
            {isLoading && <Spiner />}

            <Formik
                initialValues={{ password: "", repeatpassword: "" }}
                onSubmit={(values, actions) => {
                    setIsLoading(true);
                    setIsSubmitting(true);
                    if (values.repeatpassword === "" || values.password === "") {
                        actions.setStatus("hola");
                        setStatus("error");
                    } else {
                        setIsSubmitting(false);
                        dispatch(
                            postResetPasswordThunkAction({
                                data: {
                                    token: searchParams.get("token") || "",
                                    password: values.password,
                                },
                            })
                        )
                            .then((res: any) => {
                                if (res.error) {
                                    setModalValue("Error updating password");
                                    logger.log("Error updating password");
                                } else {
                                    setModalValue(res.payload);
                                }
                            })
                            .finally(() => setIsLoading(false));
                    }
                }}
                validationSchema={yup.object().shape({
                    password: yup
                        .string()
                        .required("Please, fill with your last name")
                        .min(8, "Please, fill with your last name"),
                    repeatpassword: yup
                        .string()
                        .required("Passwords must match")
                        .oneOf([yup.ref("password"), null], "Passwords must match"),
                })}
            >
                {({ errors, handleBlur }: any) => (
                    <Form className="userDetailsForm">
                        <div className="inputFieldDiv">
                            <div className="rowInputFields">
                                <h6>{t("register.password")}</h6>
                                <div style={{ display: "flex" }}>
                                    <Field
                                        name="password"
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder={t("register.password-label")}
                                        className="input password__input"
                                        validate={(e: any) => {
                                            setPasswordInput(e);
                                            checkPasswordStrength(passwordInput);
                                        }}
                                        onBlur={handleBlur}
                                    />
                                    <span className="show-password__icon">
                                        <img
                                            className="clickableIcon"
                                            src={isPasswordVisible ? showeye : hideeye}
                                            alt=""
                                            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                        />
                                    </span>
                                    <span className="tooltiptext">
                                        You must include at least a capital and small letter, a number an a special
                                        character.
                                    </span>
                                </div>

                                <label className="input__error">{errors.touched && errors.password}</label>
                            </div>
                            <div className="rowInputFields">
                                <h6>{t("register.repeat-password")}</h6>
                                <div style={{ display: "flex" }}>
                                    <Field
                                        name="repeatpassword"
                                        type={isRepeatPasswordVisible ? "text" : "password"}
                                        placeholder={t("register.repeat-password-label")}
                                        className="input"
                                        onBlur={handleBlur}
                                    />
                                    <span className="show-password__icon">
                                        <img
                                            src={isRepeatPasswordVisible ? showeye : hideeye}
                                            alt=""
                                            onClick={() => setIsRepeatPasswordVisible(!isRepeatPasswordVisible)}
                                        />
                                    </span>
                                </div>
                                <label className="input__error">{errors.repeatpassword && errors.repeatpassword}</label>
                            </div>
                        </div>
                        <PasswordStrengthChecker passwordStrength={passwordStrength} />
                        <div className="btn__container">
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className="resetPasswordButton"
                                style={{
                                    fontSize:
                                        window.innerWidth <= 320
                                            ? "11px"
                                            : window.innerWidth === 768
                                            ? "10px"
                                            : window.innerWidth >= 426 && window.innerWidth <= 514
                                            ? "10px"
                                            : "14px",
                                }}
                            >
                                reset password
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ResetPasswordForm;
