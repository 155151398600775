import React from 'react'
import { useTranslation } from 'react-i18next'
import TitleLabel from '../../components/common/TitleLabel/TitleLabel'
import SubtitleLabel from '../../components/common/SubtitleLabel/SubtitleLabel'
/*  import useLoginForm from '../../hooks/useLoginForm'*/
import ForgotPasswordForm from './modules/ForgotPasswordForm'
import './ForgotPassword.styles.sass'

const Login = () => {
    const [t]: any = useTranslation("global")
    /*     const {handleClick } = useLoginForm()
     */

    return (
        <div className="loginFormContainer">

            <div className='formContainer'>

                <TitleLabel label={t('forgot-password.title')} />
                <SubtitleLabel label={t('forgot-password.subtitle')} />
            <br/>
                < ForgotPasswordForm handleSubmit={''} />

            </div>
        </div>
    )
}

export default Login