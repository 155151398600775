import { combineReducers } from "@reduxjs/toolkit";
import authSliceReducer from "./auth/auth.slice";
import postsSliceReducer from "./posts/posts.slice";

const rootReducer = combineReducers({
    authReducer: authSliceReducer,
    postsReducer: postsSliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
